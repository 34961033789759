import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const WhyChooseUs = () => {
  return (
    <div className="why">
      <Container>
        <div className="why-choose-us">
          Stil și Distracție într-un Singur Loc
        </div>
        <p className="text-wrapper-14">Abonamente</p>
        <Row>
          {cards.map((card) => {
            return (
              <Col md={4} className="text-center">
                <Card border="0">
                  <Card.Body>
                    <Card.Title className="text-wrapper-16">
                      {card.title}
                    </Card.Title>
                    <div className="line-3" />
                    <Card.Text
                      className="text-wrapper-15"
                      dangerouslySetInnerHTML={{ __html: card.text }}
                    />
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default WhyChooseUs;

const cards = [
  {
    id: 1,
    image: "assets/trusted-icon.png",
    title: "SPARK",
    text: "2 servicii de tuns complete<br>rearanjare gratuită<br>8h PS5 (maxim 2h/zi)<br>băuturi din partea casei<br>acces grup privat",
  },
  {
    id: 2,
    image: "assets/masters-icon.png",
    title: "FIRE",
    text: "4 servicii de tuns complete<br>rearanjare gratuită<br>16h PS5 (maxim 2h/zi)<br>băuturi din partea casei<br>acces grup privat",
  },
  {
    id: 3,
    image: "assets/licensed-icon.png",
    title: "FLAMER",
    text: "6 servicii de tuns complete<br>rearanjare gratuită<br>24h PS5 (maxim 2h/zi)<br>băuturi din partea casei<br>acces grup privat",
  },
];

import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  return (
    <section id="footer">
      <Container fluid>
        <Row>
          <Container fluid className="hero-footer">
            <div>
              <div className="make-an-appointment">PROGRAMEAZĂ-TE!</div>

              <Row style={{ alignItems: "center", marginBlock: "15px" }}>
                <Col xs="auto" md="auto">
                  <div className="img-wrapper">
                    <img
                      className="img-2"
                      alt="Phone"
                      src="/assets/whatsapp.png"
                    />
                  </div>
                </Col>
                <Col>
                  <div style={{ textAlign: "left" }}>
                    <div className="text-wrapper-10">WhatsApp</div>
                    <div className="text-wrapper-9">0753 925 337</div>
                  </div>
                </Col>
              </Row>
              <Row style={{ alignItems: "center", marginBlock: "15px" }}>
                <Col xs="auto" md="auto">
                  <div className="img-wrapper">
                    <img
                      className="img-2"
                      alt="Envelope icon"
                      src="/assets/instagram.png"
                    />
                  </div>
                </Col>
                <Col>
                  <div style={{ textAlign: "left" }}>
                    <div className="text-wrapper-10">INSTAGRAM</div>
                    <div className="text-wrapper-9">@flamerharistudio</div>
                  </div>
                </Col>
              </Row>
              <Row style={{ alignItems: "center", marginBlock: "15px" }}>
                <Col xs="auto" md="auto">
                  <div className="img-wrapper">
                    <img
                      className="img-2"
                      alt="Envelope icon"
                      src="/assets/address.png"
                    />
                  </div>
                </Col>
                <Col>
                  <div style={{ textAlign: "left" }}>
                    <div className="text-wrapper-10">Locație</div>
                    <div className="text-wrapper-9">
                      Bulevardul Tineretului 3C. Piata Ostroveni. Etaj 1
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </Row>
        <div className="overlap-group-2">
          <p className="text-wrapper-13">
            © Copyright 2024 FlamerHairStudio - All right reserved
          </p>
        </div>
      </Container>
    </section>
  );
};

export default Footer;

import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";

const Header = () => {
  return (
    <section className="overlap-group">
      <Container fluid>
        <p className="the-ultimate">THE ULTIMATE HAIR STUDIO CONCEPT</p>
        <p className="text-wrapper">Where water can't stop your flames</p>
        <Row className="button-row">
          <Col
            xs={12}
            md={2}
            style={{ marginBlock: "15px", textAlign: "center" }}
          >
            <Button variant="default" className="frame" href="#footer">
              <div className="book-an-appointment">PROGRAMEAZĂ-TE ACUM</div>
            </Button>
          </Col>
          <Col
            xs={12}
            md={2}
            style={{ marginBlock: "15px", textAlign: "center" }}
          >
            <Button
              variant="solid"
              className="browse-services-wrapper"
              href="#services"
            >
              <div className="browse-services">SERVICILE NOASTRE</div>
            </Button>
          </Col>
        </Row>

        <Row className="infobox">
          <Col sm={4}>
            <Image
              className="img"
              alt="Address img"
              src="/assets/address-img.png"
              fluid
            />
            <div className="text-wrapper-2">Adresa</div>
            <div className="line" />
            <p className="p">
              Bulevardul Tineretului 3C. Piata Ostroveni. Etaj 1
            </p>
          </Col>
          <Col sm={4}>
            <Image
              className="img"
              alt="Phone img"
              src="/assets/phone-img.png"
              fluid
            />
            <div className="text-wrapper-2">Telefon</div>
            <div className="line" />
            <div className="element">0753925337</div>
          </Col>
          <Col sm={4}>
            <Image
              className="img"
              alt="Hours img"
              src="/assets/hours-img.png"
              fluid
            />
            <div className="text-wrapper-2">Program</div>
            <div className="line" />
            <p className="p">
              LUNI – VINERI: 10:00 – 20:00
              <br />
              SAMBATA: 09:00 – 17:00
              <br />
              DUMINICA: Inchis
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Header;

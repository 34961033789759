import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import React from "react";
import Header from "./components/header";
import Footer from "./components/footer";
import AboutUs from "./components/about";
import WhyChooseUs from "./components/whyChooseUs";
import Services from "./components/services";

function App() {
  return (
    <div className="barbershop">
      <div className="div">
        <header className="header">
          <Header />
        </header>
        <main>
          <AboutUs />
          <Services />
          <WhyChooseUs />
        </main>
        <footer className="footer">
          <Footer />
        </footer>
      </div>
    </div>
  );
}

export default App;

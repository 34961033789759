import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

const AboutUs = () => {
  return (
    <section>
      <Container fluid className="about-us">
        <Row className="align-items-center">
          <Col md={6}>
            <p className="your-personal-barber">A DIFFERENT CONCEPT</p>
            <p className="text-wrapper-4">
              Înainte/După o schimbare de look, relaxează-te și distrează-te pe
              consola noastră PlayStation 5 într-o atmosferă unde mâinile
              pricepute oferă o pauză răcoritoare.
            </p>
            <Row>
              <Col xs={6} md={12} lg={6}>
                <p className="element-2">
                  <span className="span">99</span>
                  <span className="text-wrapper-3">%</span>
                </p>
                <div className="customer">CUSTOMER SATISFACTION</div>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Image
              className="image"
              alt="Image"
              src="/assets/image00003.jpeg"
              fluid
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutUs;

import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

const Services = () => {
  return (
    <section id="services">
      <Container fluid className="services">
        <div className="browse-our-services">Serviciile noastre!</div>
        <p className="text-wrapper-5">
          Căutați un pachet adaptat pentru nevoile voastre? Avem toate
          detaliile, mai jos, legate de fiecare pachet în parte, pentru a vă
          alege exact ce aveți nevoie.
        </p>
        <Row sm={2} lg={2} className="services-2">
          {services.map((service) => {
            return (
              <Row key={service.id} style={{ alignItems: "center" }}>
                <Col xs={12} md={2} style={{ order: "1" }}>
                  <div className={service.icon} />
                </Col>
                <Col xs={12} md={10} style={{ order: "2" }}>
                  <div style={{ textAlign: "left" }}>
                    <div className="text-wrapper-6">{service.title}</div>
                    <p className="text-wrapper-8">{service.body}</p>
                    <div className="text-wrapper-7">{service.price}</div>
                  </div>
                </Col>
              </Row>
            );
          })}
        </Row>
        <Button
          variant="default"
          className="frame"
          href="#footer"
          style={{ marginTop: "35px", marginBottom: "35px" }}
        >
          <div className="book-an-appointment">PROGRAMEAZĂ-TE ACUM</div>
        </Button>
      </Container>
    </section>
  );
};

export default Services;

const services = [
  {
    id: 1,
    icon: "image-icon",
    body: "Frizerii noștri pricepuți modelează și finisează părul într-un mod profesional care accentuează stilul tău unicat.",
    title: "TUNS + SPĂLAT + ARANJAT + BARBA",
    price: "50 lei",
  },
  {
    id: 2,
    icon: "image-icon-2",
    body: "Experimentează stilul și precizia cu serviciile noastre de tuns. Stiliștii noștri experți creează look-uri personalizate pentru a se potrivi personalității și stilului tău de viață unic.",
    title: "TUNS",
    price: "40 lei",
  },
  {
    id: 3,
    icon: "image-icon-3",
    body: "De la elegant și sofisticat la ciufulit fără efort, frizerii noștri pricepuți adaptează fiecare aspect pentru a se potrivi cu atmosfera și personalitatea ta.",
    title: "ARANJAT",
    price: "10 lei",
  },
  {
    id: 4,
    icon: "image-icon-4",
    body: "Indiferent dacă preferi un stil ascuțit, definit sau un aspect robust, natural. Atenția noastră la detalii garantează un finisaj personalizat care accentuează trăsăturile feței tale.",
    title: "ARANJAT BARBA",
    price: "15 lei",
  },
  {
    id: 5,
    icon: "image-icon-5",
    body: "Răsfață-te cu o curățare luxoasă care îndepărtează ușor impuritățile, lăsându-ți șuvițele să se simtă împrospătate. Stiliștii noștri experți folosesc produse premium adaptate tipului tău de păr, oferind o experiență capilară unică.",
    title: "SPALAT",
    price: "10 lei",
  },
];
